import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';
import Project from './Project'
import TeamUser from './TeamUser'
import StripePaymentMethod from './StripePaymentMethod'

export default class Team extends BaseModel {

    static entity = 'teams'
    static eagerLoad = ['projects', 'users'];

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            serverWeb: this.string(''),
            serverWebIp: this.string(''),
            myRole: this.string(''),
            billingType: this.string(''),
            billingName: this.string(''),
            billingRegistryCode: this.string(''),
            billingVatNumber: this.string(''),
            billingEmail: this.string(''),
            billingPhone: this.string(''),
            billingAddress1: this.string(''),
            billingAddress2: this.string(''),
            billingPostalCode: this.string(''),
            billingCity: this.string(''),
            billingState: this.string(''),
            billingCountry: this.string(''),
            billingLanguage: this.string(''),
            billingMethod: this.string(''),
            billingPeriod: this.number(0),
            billingDiscount: this.number(0),
            billingDelayPayment: this.boolean(false),
            hasDueInvoices: this.boolean(false),
            currentUsage: this.string(''),
            currentUsageStarted: this.string(null),
            created: this.string(''),
            updated: this.string(''),
            deleted: this.string(null),
            projects: this.hasMany(Project, 'teamId'),
            users: this.hasMany(TeamUser, 'teamId'),
            stripePaymentMethods: this.hasMany(StripePaymentMethod, 'teamId'),
            domainCount: this.number(0),
            virtualServerCount: this.number(0),
            vmCount: this.number(0),
            vpnCount: this.number(0),
        }
    }

    static getPersonalTeam() {
        return super.getItems({
            where: ['name', '']
        })
    }

    static getNonPersonalItems() {
        const query = useRepo(this).query()
        query.where((item) => item.id > 0)
        query.where((item) => item.deleted == null)
        query.where((item) => item.name != '')
        return query.orderBy(u => u.name.toLowerCase()).get()
    }

    static isPersonalTeam(params) {
        let res = super.getItems({
            where: ['id', parseInt(params.id)]
        })
        if (res && res.length) {
            if (res[0].name == '') return true
        }
        return false
    }

    static async apiFetchSingle(teamId) {
        const query = gql`
            query getTeams ($id: Int!) {
                team(id: $id) {
                    id
                    myRole
                    name
                    serverWeb
                    serverWebIp
                    billingType
                    billingName
                    billingRegistryCode
                    billingVatNumber
                    billingEmail
                    billingPhone
                    billingAddress1
                    billingAddress2
                    billingPostalCode
                    billingCity
                    billingState
                    billingCountry
                    billingLanguage
                    billingMethod
                    billingPeriod
                    billingDiscount
                    billingDelayPayment
                    hasDueInvoices
                    currentUsage
                    currentUsageStarted
                    created
                    updated
                    deleted
                    projects {
                        id
                        name
                        color
                        description
                        created
                    }
                    users {
                        id
                        role
                        projectIds
                        projectScope
                        teamId
                        userId
                        user{
                            id
                            name
                            email
                        }
                    }
                    stripePaymentMethods{
                        id
                        uuid
                        team{
                            id
                        }
                        cardType
                        cardNumber
                        cardExpires
                        preferred
                        stripeClientSecret
                        stripePaymentMethodId
                        stripeCustomerId
                        confirmed
                        created
                        updated
                        deleted
                    }
                    domainCount
                    virtualServerCount
                    vmCount
                    vpnCount
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(teamId) })
        if (res && res.team) {
            useRepo(this).save(res.team)
        }
        return res
    }

    static async apiFetchAll(isAuthQuery) {
        const query = gql`
            query getTeams {
                teams {
                    id
                    myRole
                    name
                    serverWeb
                    serverWebIp
                    billingType
                    billingName
                    billingRegistryCode
                    billingVatNumber
                    billingEmail
                    billingPhone
                    billingAddress1
                    billingAddress2
                    billingPostalCode
                    billingCity
                    billingState
                    billingCountry
                    billingLanguage
                    billingMethod
                    billingPeriod
                    billingDiscount
                    billingDelayPayment
                    hasDueInvoices
                    currentUsage
                    currentUsageStarted
                    created
                    updated
                    deleted
                    projects {
                        id
                        name
                        color
                        description
                        created
                    }
                    users {
                        id
                        role
                        projectIds
                        projectScope
                        teamId
                        userId
                        user{
                            id
                            name
                            email
                        }
                    }
                    stripePaymentMethods{
                        id
                        uuid
                        team{
                            id
                        }
                        cardType
                        cardNumber
                        cardExpires
                        preferred
                        stripeClientSecret
                        stripePaymentMethodId
                        stripeCustomerId
                        confirmed
                        created
                        updated
                        deleted
                    }
                    domainCount
                    virtualServerCount
                    vmCount
                    vpnCount
                }
            }
        `
        // We do not save result into store when authenticating first time (teamId, projectId not in localstorage and store not yet ready)
        if (isAuthQuery) {
            return await window.$graphQLQuery(query, {}, isAuthQuery)
        }

        const res = await window.$graphQLQuery(query, {})
        if (res && res.teams) {
            useRepo(this).save(res.teams)
        }
        return res
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createTeam($team: CreateTeamInput!) {
                createTeam(team: $team){
                    id
                    myRole
                    name
                    serverWeb
                    serverWebIp
                    billingType
                    billingName
                    billingRegistryCode
                    billingVatNumber
                    billingEmail
                    billingPhone
                    billingAddress1
                    billingAddress2
                    billingPostalCode
                    billingCity
                    billingState
                    billingCountry
                    billingLanguage
                    billingMethod
                    billingPeriod
                    billingDiscount
                    billingDelayPayment
                    hasDueInvoices
                    currentUsage
                    currentUsageStarted
                    created
                    updated
                    deleted
                    projects {
                        id
                        name
                        color
                        description
                        created
                    }
                    users {
                        id
                        role
                        projectIds
                        projectScope
                        teamId
                        userId
                        user{
                            id
                            name
                            email
                        }
                    }
                    stripePaymentMethods{
                        id
                        uuid
                        team{
                            id
                        }
                        cardType
                        cardNumber
                        cardExpires
                        preferred
                        stripeClientSecret
                        stripePaymentMethodId
                        stripeCustomerId
                        confirmed
                        created
                        updated
                        deleted
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.createTeam) {

            await useRepo(this).save(res.createTeam)
        }
        return res
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateTeam($team: UpdateTeamInput!, $id: Int!) {
                updateTeam(team: $team, id: $id){
                    id
                    myRole
                    name
                    serverWeb
                    serverWebIp
                    billingType
                    billingName
                    billingRegistryCode
                    billingVatNumber
                    billingEmail
                    billingPhone
                    billingAddress1
                    billingAddress2
                    billingPostalCode
                    billingCity
                    billingState
                    billingCountry
                    billingLanguage
                    billingMethod
                    billingPeriod
                    billingDiscount
                    billingDelayPayment
                    hasDueInvoices
                    currentUsage
                    currentUsageStarted
                    created
                    updated
                    deleted
                    projects {
                        id
                        name
                        color
                        description
                        created
                    }
                    users {
                        id
                        role
                        projectIds
                        projectScope
                        teamId
                        userId
                        user{
                            id
                            name
                            email
                        }
                    }
                    stripePaymentMethods{
                        id
                        uuid
                        team{
                            id
                        }
                        cardType
                        cardNumber
                        cardExpires
                        preferred
                        stripeClientSecret
                        stripePaymentMethodId
                        stripeCustomerId
                        confirmed
                        created
                        updated
                        deleted
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateTeam) {
            await useRepo(this).save(res.updateTeam)
        }
        return res
    }

    static async apiDelete(teamId) {
        const query = gql`
            mutation deleteTeam($id: Int!) {
                deleteTeam(id: $id){
                    id
                    myRole
                    name
                    serverWeb
                    serverWebIp
                    billingType
                    billingName
                    billingRegistryCode
                    billingVatNumber
                    billingEmail
                    billingPhone
                    billingAddress1
                    billingAddress2
                    billingPostalCode
                    billingCity
                    billingState
                    billingCountry
                    billingLanguage
                    billingMethod
                    billingPeriod
                    billingDiscount
                    billingDelayPayment
                    hasDueInvoices
                    currentUsage
                    currentUsageStarted
                    created
                    updated
                    deleted
                    projects {
                        id
                        name
                        color
                        description
                        created
                    }
                    users {
                        id
                        role
                        projectIds
                        projectScope
                        teamId
                        userId
                        user{
                            id
                            name
                            email
                        }
                    }
                    stripePaymentMethods{
                        id
                        uuid
                        team{
                            id
                        }
                        cardType
                        cardNumber
                        cardExpires
                        preferred
                        stripeClientSecret
                        stripePaymentMethodId
                        stripeCustomerId
                        confirmed
                        created
                        updated
                        deleted
                    }
                }
            }
        `
        const res = await window.$graphQLQuery(query, { id: parseInt(teamId) })
        if (res && res.deleteTeam) {
            await useRepo(this).save(res.deleteTeam)
        }
        return res
    }
}

import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nodeup_sentry_de8Zw9YdS4K9f6Oy5TZzjZ_8lgj_CnF5P48wnfTy47A from "/app/client/plugins/nodeup-sentry.ts";
import nodeup_disable_console_log_1AjWCPK9t8ULMFLh9yizzvRaGDyn38Z2ygQSjkBTiJc from "/app/client/plugins/nodeup-disable-console-log.js";
import nodeup_error_M66y_rO9ttWVkMKAxGSb3uM7GTxXFj_OkbqjAyFMBOA from "/app/client/plugins/nodeup-error.js";
import nodeup_graphql_request_jHNlK6_Sb2uQMWo3gS39U_zi4N4VvsdKoBVhuQ_OeRg from "/app/client/plugins/nodeup-graphql-request.js";
import nodeup_auth_DlqJbcuPSyegFiYswiIPYwvYEVleDbAvtbxA0PNUiL4 from "/app/client/plugins/nodeup-auth.js";
import vuetify_m6SpaE94JCWKak9GKxFRJ6VTS6hq1mTTRUsoco3ytJE from "/app/client/plugins/vuetify.ts";
import nodeup_classificators_OxNF0PL_5_J_SDHBLE7voBPYqMKJUA7fjMeI7PEIums from "/app/client/plugins/nodeup-classificators.js";
import nodeup_utils_JaVBXJh79JkP41P_LltIE8hVejZ8QNW0MlV7kAnR1yQ from "/app/client/plugins/nodeup-utils.js";
import nodeup_is_mobile_e6Y1yhEaIL5w3aDJdNSw4cNPDWz090IZIGT3jGHavC4 from "/app/client/plugins/nodeup-is-mobile.js";
import nodeup_intercom_Ws18QZ7DJ_2Ju8YLXD4vnC3pGIWsjebslkk4xuPx2bw from "/app/client/plugins/nodeup-intercom.js";
import nodeup_reload_9JAk1gmVVCULLhaR_XswsqdloEC9HpSeVIcvageVCVM from "/app/client/plugins/nodeup-reload.js";
import nodeup_websocket_updates_zbjQ6NsJvQCDASc8nl1SH7M_sxZIdXGNn1kJLHLMmOM from "/app/client/plugins/nodeup-websocket-updates.js";
import nodeup_directives_qdvGw8eURZN7KmO9Gj9ehAIGwSLfqf3ULNKUBdXPQBU from "/app/client/plugins/nodeup-directives.js";
import plugin_vue3_h7hGp5M9igJgp9fnoOpgMa3KIFuigDjEPZ_bfSN5kRs from "/app/node_modules/@pinia-orm/nuxt/dist/runtime/plugin.vue3.js";
import dayjs_C4uXz9Hlu3CmXAkmqxR1CeDd1L3vA_n9iu5Qx4tRUQs from "/app/client/plugins/dayjs.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  nodeup_sentry_de8Zw9YdS4K9f6Oy5TZzjZ_8lgj_CnF5P48wnfTy47A,
  nodeup_disable_console_log_1AjWCPK9t8ULMFLh9yizzvRaGDyn38Z2ygQSjkBTiJc,
  nodeup_error_M66y_rO9ttWVkMKAxGSb3uM7GTxXFj_OkbqjAyFMBOA,
  nodeup_graphql_request_jHNlK6_Sb2uQMWo3gS39U_zi4N4VvsdKoBVhuQ_OeRg,
  nodeup_auth_DlqJbcuPSyegFiYswiIPYwvYEVleDbAvtbxA0PNUiL4,
  vuetify_m6SpaE94JCWKak9GKxFRJ6VTS6hq1mTTRUsoco3ytJE,
  nodeup_classificators_OxNF0PL_5_J_SDHBLE7voBPYqMKJUA7fjMeI7PEIums,
  nodeup_utils_JaVBXJh79JkP41P_LltIE8hVejZ8QNW0MlV7kAnR1yQ,
  nodeup_is_mobile_e6Y1yhEaIL5w3aDJdNSw4cNPDWz090IZIGT3jGHavC4,
  nodeup_intercom_Ws18QZ7DJ_2Ju8YLXD4vnC3pGIWsjebslkk4xuPx2bw,
  nodeup_reload_9JAk1gmVVCULLhaR_XswsqdloEC9HpSeVIcvageVCVM,
  nodeup_websocket_updates_zbjQ6NsJvQCDASc8nl1SH7M_sxZIdXGNn1kJLHLMmOM,
  nodeup_directives_qdvGw8eURZN7KmO9Gj9ehAIGwSLfqf3ULNKUBdXPQBU,
  plugin_vue3_h7hGp5M9igJgp9fnoOpgMa3KIFuigDjEPZ_bfSN5kRs,
  dayjs_C4uXz9Hlu3CmXAkmqxR1CeDd1L3vA_n9iu5Qx4tRUQs
]
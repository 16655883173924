
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as apikeysHLuRHcxoGV_zEwNlhnPkSDkWE1xkWmDwNk9HVuougoMMeta } from "/app/client/pages/account/apikeys.vue?macro=true";
import { default as index6lltOZVXXWCWXI_456fdykTpT3_45YorbhRtZHPv2jrqp6EMeta } from "/app/client/pages/account/index.vue?macro=true";
import { default as sshkeyspJGsWtBESn_I5b25CNqusta4AX8VCq6muwjfb6JRndYMeta } from "/app/client/pages/account/sshkeys.vue?macro=true";
import { default as accountqKUjETV4y343VhipIy8xi_45R6DU8w_45ZXYlLOn5O_A2A8Meta } from "/app/client/pages/account.vue?macro=true";
import { default as componentswDhWW4JXfMfU7pBl9mlvvpsZ_45J5YV_45av2Hrs04LfvocMeta } from "/app/client/pages/apps/[id]/components.vue?macro=true";
import { default as indexNqa_45fVUBarfQSTPOjlBD3kYfLcqQhF5GkS0P5AMNYT4Meta } from "/app/client/pages/apps/[id]/index.vue?macro=true";
import { default as _91id_93dj3OoyO2eOUwa8_45XnlFQuRqi6KvktDYowqBg4GEUO1cMeta } from "/app/client/pages/apps/[id].vue?macro=true";
import { default as indexw9Y4rHlsa2mXRV_mJ0jvCdf9lRvl4A963egjdx_qC8cMeta } from "/app/client/pages/apps/index.vue?macro=true";
import { default as currentusageGL_454zhZKWYZrhnP1DcmJx0Ww7_4535o_AGq5F9h4EYxKgMeta } from "/app/client/pages/billing/currentusage.vue?macro=true";
import { default as index7qwez2_45c9DZEC_45i_zcwHLwtn4rAEZU5tl_45ivt9FnGGsMeta } from "/app/client/pages/billing/index.vue?macro=true";
import { default as ordersINUOBZjAwoTTSLH7RqRKkQXNxXnTBgzPdhSGqgEht6EMeta } from "/app/client/pages/billing/orders.vue?macro=true";
import { default as settings2ZwMiyd_WG_w2J0sjuTV58T2rV4KroG_45QG_Txw9ScwcMeta } from "/app/client/pages/billing/settings.vue?macro=true";
import { default as billing2VT_453lq1dzcOgI_45uQuQJG2E5XG3l3DB_45OpRaVzcHp9oMeta } from "/app/client/pages/billing.vue?macro=true";
import { default as emailf0DngIPRNYDqK83n7Dl6e_VY3SPJjeBZL6MBbsjlP2QMeta } from "/app/client/pages/confirm/email.vue?macro=true";
import { default as index7eKjmJXpQ7zWVNAODV_d1Nmwq43HYLR7ff0_45DtldAikMeta } from "/app/client/pages/confirm/index.vue?macro=true";
import { default as contactsryMC2jT2r0N875_L8uGHcd_45JRgXyuujOcKVW5vvxqpQMeta } from "/app/client/pages/domains/[id]/contacts.vue?macro=true";
import { default as dnsrecords2Q1DhO25T_45mHvr0NVxJfx3sWHvckTLYWj55tZeb3__45QMeta } from "/app/client/pages/domains/[id]/dnsrecords.vue?macro=true";
import { default as indexG3JWj87ObZfkGxc_HNSWtkkJmme52wzVKCvKXVEK8aQMeta } from "/app/client/pages/domains/[id]/index.vue?macro=true";
import { default as nameserversL0a3bfAsbOb_45rgiUn3rHuZuEZgPsuURD1YC_45id55zv8Meta } from "/app/client/pages/domains/[id]/nameservers.vue?macro=true";
import { default as _91id_93J8E85lHPC51gPVO1tRHwqfy5jWtF7TI0k4WLveQqa1wMeta } from "/app/client/pages/domains/[id].vue?macro=true";
import { default as indexG_45FHqd_b2AwneD_acBjQCBPgiqBW_v4_45SThcQhidAvsMeta } from "/app/client/pages/domains/index.vue?macro=true";
import { default as newh_45G81_45Dr_45hI2tpTjo5_45clWo1_45wHtsaQ289j6vu8KRo4Meta } from "/app/client/pages/domains/new.vue?macro=true";
import { default as thankyou_CY2mzLV0kDNKwhVqqhOOK4YRIaAHKy9UH8uMVygfEgMeta } from "/app/client/pages/domains/thankyou.vue?macro=true";
import { default as forgotI7So6fMxEakCuxCsBMBOzgY5sGj9RRCnD9AJkojzC6kMeta } from "/app/client/pages/forgot.vue?macro=true";
import { default as googleRR2RR5PJztqoILFqnoDT1L6q25byXYTLGyjSbjJvhA8Meta } from "/app/client/pages/google.vue?macro=true";
import { default as index5KVldyFMYWY_45brh_45RgytIP5hDSTN6fSjoHd5KWp5trkMeta } from "/app/client/pages/index.vue?macro=true";
import { default as _91id_93HjY148oUnIpX5HLyFBAwOFKVwRY3BJ_45HGqeBHOPxvsoMeta } from "/app/client/pages/invoices/[id].vue?macro=true";
import { default as loginkSvVTYSsh0w5BZ3rKst9YU2OFqcyCviS8Z_45Bfo7nCWEMeta } from "/app/client/pages/login.vue?macro=true";
import { default as montonio_45notifyR3MB_454Zx01J4xUUrHeP9ivMoFbqMFSjm7aT0lxSN_4578Meta } from "/app/client/pages/montonio-notify.vue?macro=true";
import { default as activitiesde89qdEC9TOjKoS3TrrPpbFqia_45qLAUr4z5KV9ISVAUMeta } from "/app/client/pages/projects/[id]/activities.vue?macro=true";
import { default as indexFwYhPXpphbL0AyVX0Wiy8PGNtRoKXzop1KCBGz45JRQMeta } from "/app/client/pages/projects/[id]/index.vue?macro=true";
import { default as settingsqVOX6hMayUWEyl6yqR00Irkxm5miaZJMXKNq1L0A_8EMeta } from "/app/client/pages/projects/[id]/settings.vue?macro=true";
import { default as _91id_93RgX2Vir_34_45Fuz8YUIH9KENV2MLFbFQq01Ebt2cpFdAMeta } from "/app/client/pages/projects/[id].vue?macro=true";
import { default as _0PP4CV9SsFrmL8MY8xU_45adaUMczfhKtVn4U6cB22QgnoMeta } from "/app/client/pages/projects/0.vue?macro=true";
import { default as indexiiCvBgS03rRjqbLaFZcMat670mDtij5fj8fIhcSO_rAMeta } from "/app/client/pages/projects/index.vue?macro=true";
import { default as apiVxYy85191yFOj2dTOMeMt_1XMKr7QvIXvkr4TVOfGJkMeta } from "/app/client/pages/settings/api.vue?macro=true";
import { default as index8v3ToE6FYiKbMCktzKfYErnjvx61hE4fPl7_45eCe1XcAMeta } from "/app/client/pages/settings/index.vue?macro=true";
import { default as teamOL8Fhvbmr6q9tMOU0as4K_45wE_45SRe3SkSZtkrA6EFc4cMeta } from "/app/client/pages/settings/team.vue?macro=true";
import { default as settingsTj9g_Fa_45gbsHdq1hinGVqwYOemoHO46zSag3tlzjAlQMeta } from "/app/client/pages/settings.vue?macro=true";
import { default as signup4VD_9XNc8z0StfeRWQBL5Xd0lyHgoN5gbIiIFIp0eiEMeta } from "/app/client/pages/signup.vue?macro=true";
import { default as backupswfpR187e2IdSvMipucDAkpoOEQdinuXlEyxL6b5_CtcMeta } from "/app/client/pages/virtualservers/[id]/backups.vue?macro=true";
import { default as basicauthsILCvGS96SoVx7BdhScLko1ZF7_45YVl1wjWr0eBkbr0_454Meta } from "/app/client/pages/virtualservers/[id]/basicauths.vue?macro=true";
import { default as cronjobsYi2Oj2jI7kq9eJAddho9r6m5cDuR6ouFhHuxiGwvWeIMeta } from "/app/client/pages/virtualservers/[id]/cronjobs.vue?macro=true";
import { default as databaseslne2MeqN3b9qR8x9keJpn7c_2VON_0X_45_45wV8_45mxjf5MMeta } from "/app/client/pages/virtualservers/[id]/databases.vue?macro=true";
import { default as emaillists4CeAupPbdWkDnl6qatYgMD_4fLrwVauIrQDZhGFU88MMeta } from "/app/client/pages/virtualservers/[id]/emaillists.vue?macro=true";
import { default as emailsOMaJI83BF96qJVlbUhoWz3rjvVoXCB1QySKU8wLC85gMeta } from "/app/client/pages/virtualservers/[id]/emails.vue?macro=true";
import { default as ftpsdO5lGmvkS6bu6FToOrSZeNOyWrv0vxE9_5t88qN9VBkMeta } from "/app/client/pages/virtualservers/[id]/ftps.vue?macro=true";
import { default as indexKbhcvfhZcP_45qLMAEV7a9fwM8RxXkFpdYFj_45u5LoMys0Meta } from "/app/client/pages/virtualservers/[id]/index.vue?macro=true";
import { default as ssh3Xm4pkcS6wOB6myT9z1Y2K3V7t1WpN5xGnC93_45vF7vkMeta } from "/app/client/pages/virtualservers/[id]/ssh.vue?macro=true";
import { default as subdomainsqcAd9tBxzlS57a6eYjH7dEX9oYdBU4nGmYR2I5x06IYMeta } from "/app/client/pages/virtualservers/[id]/subdomains.vue?macro=true";
import { default as _91id_93EJFoXYyk2ihGGX8Zbkv_6MwU_45yCYVRgm16N6wh_45rsGIMeta } from "/app/client/pages/virtualservers/[id].vue?macro=true";
import { default as index_SUoJaScNAmqXLA20GZXAVi_nQjjXLxjDBpkEqLz3dIMeta } from "/app/client/pages/virtualservers/index.vue?macro=true";
import { default as console9_yk6ntn449UTRZELla5XQgFu9EGWEF5j7s16pR1RCMMeta } from "/app/client/pages/vms/[id]/console.vue?macro=true";
import { default as indexkLv9JsObz3jYfgZTYWXQb8Qrq8w_maLx89dpYGBXhQkMeta } from "/app/client/pages/vms/[id]/index.vue?macro=true";
import { default as statisticsM2awR9gWAgOStA4wFrp9AoYEw0Ol74aQ3e_45RtPlY95IMeta } from "/app/client/pages/vms/[id]/statistics.vue?macro=true";
import { default as _91id_93JiXSgPJpSzNhJ_45c0vFkd9IrSnYi2MoI2MC8pBC_dtdgMeta } from "/app/client/pages/vms/[id].vue?macro=true";
import { default as index6B1_fyO3oWtIcCJ4kariea3z3_454r3JDJqakmnydPIxwMeta } from "/app/client/pages/vms/index.vue?macro=true";
import { default as index4CuNhU6dQMR_45StoSV2rBPW8A8VlV3oEeIjDmEN1Q5HkMeta } from "/app/client/pages/vpns/[id]/index.vue?macro=true";
import { default as networksOdTRD05UmicYQRM6IKaZoowKeQC_aKQIu9kdlmRIIV4Meta } from "/app/client/pages/vpns/[id]/networks.vue?macro=true";
import { default as userssVqzecwd5AJ1Z0aXivm3lRocoD_45bM5enmpiB5QHE6goMeta } from "/app/client/pages/vpns/[id]/users.vue?macro=true";
import { default as _91id_93_ezOHrX7Mh8Vp4Aifq6pvsZvcGK7SXNGI8VagQTdu80Meta } from "/app/client/pages/vpns/[id].vue?macro=true";
import { default as indexy829YPzjJHBRkf3lrug9YF_45vFDheng_457teP7vl_45nvvUMeta } from "/app/client/pages/vpns/index.vue?macro=true";
export default [
  {
    name: accountqKUjETV4y343VhipIy8xi_45R6DU8w_45ZXYlLOn5O_A2A8Meta?.name,
    path: "/account",
    meta: accountqKUjETV4y343VhipIy8xi_45R6DU8w_45ZXYlLOn5O_A2A8Meta || {},
    component: () => import("/app/client/pages/account.vue"),
    children: [
  {
    name: "account-apikeys",
    path: "apikeys",
    meta: apikeysHLuRHcxoGV_zEwNlhnPkSDkWE1xkWmDwNk9HVuougoMMeta || {},
    component: () => import("/app/client/pages/account/apikeys.vue")
  },
  {
    name: "account",
    path: "",
    meta: index6lltOZVXXWCWXI_456fdykTpT3_45YorbhRtZHPv2jrqp6EMeta || {},
    component: () => import("/app/client/pages/account/index.vue")
  },
  {
    name: "account-sshkeys",
    path: "sshkeys",
    meta: sshkeyspJGsWtBESn_I5b25CNqusta4AX8VCq6muwjfb6JRndYMeta || {},
    component: () => import("/app/client/pages/account/sshkeys.vue")
  }
]
  },
  {
    name: _91id_93dj3OoyO2eOUwa8_45XnlFQuRqi6KvktDYowqBg4GEUO1cMeta?.name,
    path: "/apps/:id()",
    meta: _91id_93dj3OoyO2eOUwa8_45XnlFQuRqi6KvktDYowqBg4GEUO1cMeta || {},
    component: () => import("/app/client/pages/apps/[id].vue"),
    children: [
  {
    name: "apps-id-components",
    path: "components",
    meta: componentswDhWW4JXfMfU7pBl9mlvvpsZ_45J5YV_45av2Hrs04LfvocMeta || {},
    component: () => import("/app/client/pages/apps/[id]/components.vue")
  },
  {
    name: "apps-id",
    path: "",
    meta: indexNqa_45fVUBarfQSTPOjlBD3kYfLcqQhF5GkS0P5AMNYT4Meta || {},
    component: () => import("/app/client/pages/apps/[id]/index.vue")
  }
]
  },
  {
    name: "apps",
    path: "/apps",
    meta: indexw9Y4rHlsa2mXRV_mJ0jvCdf9lRvl4A963egjdx_qC8cMeta || {},
    component: () => import("/app/client/pages/apps/index.vue")
  },
  {
    name: billing2VT_453lq1dzcOgI_45uQuQJG2E5XG3l3DB_45OpRaVzcHp9oMeta?.name,
    path: "/billing",
    meta: billing2VT_453lq1dzcOgI_45uQuQJG2E5XG3l3DB_45OpRaVzcHp9oMeta || {},
    component: () => import("/app/client/pages/billing.vue"),
    children: [
  {
    name: "billing-currentusage",
    path: "currentusage",
    meta: currentusageGL_454zhZKWYZrhnP1DcmJx0Ww7_4535o_AGq5F9h4EYxKgMeta || {},
    component: () => import("/app/client/pages/billing/currentusage.vue")
  },
  {
    name: "billing",
    path: "",
    component: () => import("/app/client/pages/billing/index.vue")
  },
  {
    name: "billing-orders",
    path: "orders",
    meta: ordersINUOBZjAwoTTSLH7RqRKkQXNxXnTBgzPdhSGqgEht6EMeta || {},
    component: () => import("/app/client/pages/billing/orders.vue")
  },
  {
    name: "billing-settings",
    path: "settings",
    meta: settings2ZwMiyd_WG_w2J0sjuTV58T2rV4KroG_45QG_Txw9ScwcMeta || {},
    component: () => import("/app/client/pages/billing/settings.vue")
  }
]
  },
  {
    name: "confirm-email",
    path: "/confirm/email",
    meta: emailf0DngIPRNYDqK83n7Dl6e_VY3SPJjeBZL6MBbsjlP2QMeta || {},
    component: () => import("/app/client/pages/confirm/email.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: index7eKjmJXpQ7zWVNAODV_d1Nmwq43HYLR7ff0_45DtldAikMeta || {},
    component: () => import("/app/client/pages/confirm/index.vue")
  },
  {
    name: _91id_93J8E85lHPC51gPVO1tRHwqfy5jWtF7TI0k4WLveQqa1wMeta?.name,
    path: "/domains/:id()",
    meta: _91id_93J8E85lHPC51gPVO1tRHwqfy5jWtF7TI0k4WLveQqa1wMeta || {},
    component: () => import("/app/client/pages/domains/[id].vue"),
    children: [
  {
    name: "domains-id-contacts",
    path: "contacts",
    component: () => import("/app/client/pages/domains/[id]/contacts.vue")
  },
  {
    name: "domains-id-dnsrecords",
    path: "dnsrecords",
    component: () => import("/app/client/pages/domains/[id]/dnsrecords.vue")
  },
  {
    name: "domains-id",
    path: "",
    meta: indexG3JWj87ObZfkGxc_HNSWtkkJmme52wzVKCvKXVEK8aQMeta || {},
    component: () => import("/app/client/pages/domains/[id]/index.vue")
  },
  {
    name: "domains-id-nameservers",
    path: "nameservers",
    component: () => import("/app/client/pages/domains/[id]/nameservers.vue")
  }
]
  },
  {
    name: "domains",
    path: "/domains",
    meta: indexG_45FHqd_b2AwneD_acBjQCBPgiqBW_v4_45SThcQhidAvsMeta || {},
    component: () => import("/app/client/pages/domains/index.vue")
  },
  {
    name: "domains-new",
    path: "/domains/new",
    meta: newh_45G81_45Dr_45hI2tpTjo5_45clWo1_45wHtsaQ289j6vu8KRo4Meta || {},
    component: () => import("/app/client/pages/domains/new.vue")
  },
  {
    name: "domains-thankyou",
    path: "/domains/thankyou",
    meta: thankyou_CY2mzLV0kDNKwhVqqhOOK4YRIaAHKy9UH8uMVygfEgMeta || {},
    component: () => import("/app/client/pages/domains/thankyou.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotI7So6fMxEakCuxCsBMBOzgY5sGj9RRCnD9AJkojzC6kMeta || {},
    component: () => import("/app/client/pages/forgot.vue")
  },
  {
    name: "google",
    path: "/google",
    meta: googleRR2RR5PJztqoILFqnoDT1L6q25byXYTLGyjSbjJvhA8Meta || {},
    component: () => import("/app/client/pages/google.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index5KVldyFMYWY_45brh_45RgytIP5hDSTN6fSjoHd5KWp5trkMeta || {},
    component: () => import("/app/client/pages/index.vue")
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_93HjY148oUnIpX5HLyFBAwOFKVwRY3BJ_45HGqeBHOPxvsoMeta || {},
    component: () => import("/app/client/pages/invoices/[id].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginkSvVTYSsh0w5BZ3rKst9YU2OFqcyCviS8Z_45Bfo7nCWEMeta || {},
    component: () => import("/app/client/pages/login.vue")
  },
  {
    name: "montonio-notify",
    path: "/montonio-notify",
    meta: montonio_45notifyR3MB_454Zx01J4xUUrHeP9ivMoFbqMFSjm7aT0lxSN_4578Meta || {},
    component: () => import("/app/client/pages/montonio-notify.vue")
  },
  {
    name: _91id_93RgX2Vir_34_45Fuz8YUIH9KENV2MLFbFQq01Ebt2cpFdAMeta?.name,
    path: "/projects/:id()",
    meta: _91id_93RgX2Vir_34_45Fuz8YUIH9KENV2MLFbFQq01Ebt2cpFdAMeta || {},
    component: () => import("/app/client/pages/projects/[id].vue"),
    children: [
  {
    name: "projects-id-activities",
    path: "activities",
    meta: activitiesde89qdEC9TOjKoS3TrrPpbFqia_45qLAUr4z5KV9ISVAUMeta || {},
    component: () => import("/app/client/pages/projects/[id]/activities.vue")
  },
  {
    name: "projects-id",
    path: "",
    meta: indexFwYhPXpphbL0AyVX0Wiy8PGNtRoKXzop1KCBGz45JRQMeta || {},
    component: () => import("/app/client/pages/projects/[id]/index.vue")
  },
  {
    name: "projects-id-settings",
    path: "settings",
    meta: settingsqVOX6hMayUWEyl6yqR00Irkxm5miaZJMXKNq1L0A_8EMeta || {},
    component: () => import("/app/client/pages/projects/[id]/settings.vue")
  }
]
  },
  {
    name: "projects-0",
    path: "/projects/0",
    meta: _0PP4CV9SsFrmL8MY8xU_45adaUMczfhKtVn4U6cB22QgnoMeta || {},
    component: () => import("/app/client/pages/projects/0.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexiiCvBgS03rRjqbLaFZcMat670mDtij5fj8fIhcSO_rAMeta || {},
    component: () => import("/app/client/pages/projects/index.vue")
  },
  {
    name: settingsTj9g_Fa_45gbsHdq1hinGVqwYOemoHO46zSag3tlzjAlQMeta?.name,
    path: "/settings",
    meta: settingsTj9g_Fa_45gbsHdq1hinGVqwYOemoHO46zSag3tlzjAlQMeta || {},
    component: () => import("/app/client/pages/settings.vue"),
    children: [
  {
    name: "settings-api",
    path: "api",
    meta: apiVxYy85191yFOj2dTOMeMt_1XMKr7QvIXvkr4TVOfGJkMeta || {},
    component: () => import("/app/client/pages/settings/api.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/app/client/pages/settings/index.vue")
  },
  {
    name: "settings-team",
    path: "team",
    meta: teamOL8Fhvbmr6q9tMOU0as4K_45wE_45SRe3SkSZtkrA6EFc4cMeta || {},
    component: () => import("/app/client/pages/settings/team.vue")
  }
]
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup4VD_9XNc8z0StfeRWQBL5Xd0lyHgoN5gbIiIFIp0eiEMeta || {},
    component: () => import("/app/client/pages/signup.vue")
  },
  {
    name: _91id_93EJFoXYyk2ihGGX8Zbkv_6MwU_45yCYVRgm16N6wh_45rsGIMeta?.name,
    path: "/virtualservers/:id()",
    meta: _91id_93EJFoXYyk2ihGGX8Zbkv_6MwU_45yCYVRgm16N6wh_45rsGIMeta || {},
    component: () => import("/app/client/pages/virtualservers/[id].vue"),
    children: [
  {
    name: "virtualservers-id-backups",
    path: "backups",
    component: () => import("/app/client/pages/virtualservers/[id]/backups.vue")
  },
  {
    name: "virtualservers-id-basicauths",
    path: "basicauths",
    component: () => import("/app/client/pages/virtualservers/[id]/basicauths.vue")
  },
  {
    name: "virtualservers-id-cronjobs",
    path: "cronjobs",
    component: () => import("/app/client/pages/virtualservers/[id]/cronjobs.vue")
  },
  {
    name: "virtualservers-id-databases",
    path: "databases",
    component: () => import("/app/client/pages/virtualservers/[id]/databases.vue")
  },
  {
    name: "virtualservers-id-emaillists",
    path: "emaillists",
    component: () => import("/app/client/pages/virtualservers/[id]/emaillists.vue")
  },
  {
    name: "virtualservers-id-emails",
    path: "emails",
    component: () => import("/app/client/pages/virtualservers/[id]/emails.vue")
  },
  {
    name: "virtualservers-id-ftps",
    path: "ftps",
    component: () => import("/app/client/pages/virtualservers/[id]/ftps.vue")
  },
  {
    name: "virtualservers-id",
    path: "",
    meta: indexKbhcvfhZcP_45qLMAEV7a9fwM8RxXkFpdYFj_45u5LoMys0Meta || {},
    component: () => import("/app/client/pages/virtualservers/[id]/index.vue")
  },
  {
    name: "virtualservers-id-ssh",
    path: "ssh",
    component: () => import("/app/client/pages/virtualservers/[id]/ssh.vue")
  },
  {
    name: "virtualservers-id-subdomains",
    path: "subdomains",
    component: () => import("/app/client/pages/virtualservers/[id]/subdomains.vue")
  }
]
  },
  {
    name: "virtualservers",
    path: "/virtualservers",
    meta: index_SUoJaScNAmqXLA20GZXAVi_nQjjXLxjDBpkEqLz3dIMeta || {},
    component: () => import("/app/client/pages/virtualservers/index.vue")
  },
  {
    name: _91id_93JiXSgPJpSzNhJ_45c0vFkd9IrSnYi2MoI2MC8pBC_dtdgMeta?.name,
    path: "/vms/:id()",
    meta: _91id_93JiXSgPJpSzNhJ_45c0vFkd9IrSnYi2MoI2MC8pBC_dtdgMeta || {},
    component: () => import("/app/client/pages/vms/[id].vue"),
    children: [
  {
    name: "vms-id-console",
    path: "console",
    meta: console9_yk6ntn449UTRZELla5XQgFu9EGWEF5j7s16pR1RCMMeta || {},
    component: () => import("/app/client/pages/vms/[id]/console.vue")
  },
  {
    name: "vms-id",
    path: "",
    component: () => import("/app/client/pages/vms/[id]/index.vue")
  },
  {
    name: "vms-id-statistics",
    path: "statistics",
    meta: statisticsM2awR9gWAgOStA4wFrp9AoYEw0Ol74aQ3e_45RtPlY95IMeta || {},
    component: () => import("/app/client/pages/vms/[id]/statistics.vue")
  }
]
  },
  {
    name: "vms",
    path: "/vms",
    meta: index6B1_fyO3oWtIcCJ4kariea3z3_454r3JDJqakmnydPIxwMeta || {},
    component: () => import("/app/client/pages/vms/index.vue")
  },
  {
    name: _91id_93_ezOHrX7Mh8Vp4Aifq6pvsZvcGK7SXNGI8VagQTdu80Meta?.name,
    path: "/vpns/:id()",
    meta: _91id_93_ezOHrX7Mh8Vp4Aifq6pvsZvcGK7SXNGI8VagQTdu80Meta || {},
    component: () => import("/app/client/pages/vpns/[id].vue"),
    children: [
  {
    name: "vpns-id",
    path: "",
    component: () => import("/app/client/pages/vpns/[id]/index.vue")
  },
  {
    name: "vpns-id-networks",
    path: "networks",
    component: () => import("/app/client/pages/vpns/[id]/networks.vue")
  },
  {
    name: "vpns-id-users",
    path: "users",
    component: () => import("/app/client/pages/vpns/[id]/users.vue")
  }
]
  },
  {
    name: "vpns",
    path: "/vpns",
    meta: indexy829YPzjJHBRkf3lrug9YF_45vFDheng_457teP7vl_45nvvUMeta || {},
    component: () => import("/app/client/pages/vpns/index.vue")
  }
]